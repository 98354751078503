<template>
  <div class="login">
    <div class="login-form-title">فراموشی رمز عبور</div>
    <div>
      <hr />
    </div>
    <form action="" @submit.prevent="resevedCode">
      <div>
        <label for="">
          ایمیل
          <input type="text" v-model="dataEmail.email" />
        </label>
        <p class="error-p" v-if="ErrorText.length">
          {{ ErrorText[0] }}
        </p>
      </div>
      <div>
        <button type="submit" class="forgot-btn" :disabled="!dataEmail.email">
          دریافت کد
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  components: {},
  data() {
    return {
      dataEmail: {
        email: "",
      },
      ErrorText: "",
    };
  },
  methods: {
    async resevedCode() {
      this.state.loading=true
      try {
        const send = await this.$axios.post(
          "/forget-password",
          this.dataEmail
        );
        if(send.data.type=='success') {
            this.$router.push('/forget-confirm') 
            this.state.email=this.dataEmail.email
            this.state.method = send.data.data.method
        }
      } catch (err) {
        const res = err.response || err.toJSON();
        if(res.data.errors) {
                 this.ErrorText = res.data.errors.email;
             }
             else {
                 this.ErrorText = res.data.message
             }
      }
      //   console.log('send',send)
      this.state.loading=false
    },
  },
};
</script>

<style scoped>
.login,
form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.login-form-title {
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
}
hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(90deg, black, white, black);
}
label,
input {
  width: 100%;
  /* background: #212225; */
  border-radius: 5px;
  border: 0;
}
label {
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  /* background: rgba(255, 255, 255, 0.04); */
}
input {
  height: 40px;
  color: rgba(255, 255, 255, 0.7);
}
.forgot-btn {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background: linear-gradient(180deg, #ffb007 0%, #ff9c07 100%);
  border: 0;
  font-weight: 500;
  font-size: 16px;
}
.sub-link {
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
}
.link-p {
  text-decoration-line: underline;
  color: #53acff;
}
.google-btn {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  background: transparent;
  color: #ffffff;
  height: 50px;
}
.forget-a {
  margin: 0;
  text-align: left;
}
.forget-a > a {
  text-decoration-line: underline;
  color: #ffffff;
  font-weight: normal;
  font-size: 12px;
}
.error-p {
}
</style>
