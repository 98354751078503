<template>
<transition name="modal-fade">
    <div class="modal-backdrop" @click.self="close">
        <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
            <header class="modal-header" id="modalTitle">
                <slot name="header">
                    <button type="button" class="confirm-exit-modal" @click="close" aria-label="Close modal">
                        <img src="@/assets/close-modal.png" alt="">
                    </button>
                    <p class="header">واریز</p>
                </slot>
                <button type="button" class="btn-close" @click="close" aria-label="Close modal">
                    <!-- <img src="./closeModal.png" alt=""> -->
                </button>
            </header>

            <section class="modal-body" id="modalDescription">
                <div name="body" class="body">
                    <label for="" class="coin-type-label" > انتخاب شماره کارت
                        <div class="selected-crypto" @click="showAccounts">

                            <span v-if="selectedCard != ''">{{selectedCard}}</span>

                        </div>
                        <img class="drop-img coin-drop" src="@/assets/caretdown.png" alt=""  @click="showAccounts"/>

                        <div v-if="show" class="carts_drop">
                            <div class="drop-row" v-for="(account, index) in accounts" :key="index" @click="selectCard(index)">
                                <span class="drop-name">{{ account.card_number }}</span>
                            </div>
                        </div>
                    </label>
                    <label for="">
                        مبلغ 
                        <input type="text" v-model="data.amount" @input="data.amount = $toLocal(data.amount)">
                    </label>
                    <p class="error-p">
                        {{amountError[0]}}
                    </p>
                    <div class="banks-container">
                        <template v-for="(payment , index) in payments" >
                            <img :key="index" class="pay_img" :src="payment.image" @click="payment.click">
                        </template>
                    </div>
                    <p class="error-p">
                        {{errorText}}
                    </p>
                    <button class="buy-btn" @click="deposit" :disabled="data.amount == '' && data.account_id == '' && data.gateway==''">
                        واریز وجه
                    </button>
                </div>

            </section>

            <footer class="modal-footer">
            </footer>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    name: 'Deposit',
    data() {
        return {
            show: false,
            accounts: [],
            selectedCard: '',
            errorText: '',
            amountError:'',
            link: "",
            payments: [{
                image: require('../../../assets/zibal.png'),
                click : () => {
                    this.data.gateway = 'zibal'
                    console.log(this.data)
                }
            } , {
                image: require('../../../assets/vandar.png'),
                click : () => {
                    this.data.gateway = 'vandar'
                    console.log(this.data)
                }
            }],
            data: {
                payment_method: "ONLINE",
                amount: '',
                account_id: '',
                gateway: 'zibal',
                coin_id: 1
            }
        }
        
    },
    methods: {
        close() {
            this.$emit('close');
        },
        showAccounts() {
            this.show = !this.show
        },
        selectCard(index) {
            this.selectedCard = this.accounts[index].card_number
            this.data.account_id = this.accounts[index].id
            this.show = false
        },
        async accountInfo() {
            const res = await this.$axios.get(
                "/accounts?status=ACCEPTED", {
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get('token')}`
                    }
                }
            )
            this.accounts = res.data.data
        },
        async deposit() {
            this.data.amount = this.$S2N(this.data.amount)
            this.state.loading = true
            try {
                const res = await this.$axios.post(
                    '/transactions/type/deposit', this.data, {
                        headers: {
                            'Authorization': `Bearer ${this.$cookies.get('token')}`
                        }
                    }
                )
                this.link = res.data.data.link
                
                window.location.href = this.link
                // this.state.success = true
            } catch (err) {
                const error = err.response || err.toJSON();
                this.errorText = error.data.message
                if(error.data.errors.amount) {
                    this.amountError = error.data.errors.amount
                }
            }
            
            
            this.state.loading = false

        },


        

    },
    mounted() {
        this.accountInfo()
    }
};
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    background: linear-gradient(180deg, #15161A 0%, #15161A 100%);
    /* shadow */

    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    min-height: 365px;
    width: 450px;
}

.modal-header {
    padding: 0 15px;
    display: flex;
    position: relative;
    column-gap: 50px;
    align-items: center;
    justify-content: center;
}

.header {
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
}

.modal-header {
    position: relative;
    /* border-bottom: 1px solid #eeeeee; */
    /* color: #4AAE9B; */
    display: flex;
    /* justify-content: center; */
    text-align: right;
    direction: rtl;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

/* .modal-header > slot > p {
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    font-family: shabnam;
  } */

.modal-footer {
    /* border-top: 1px solid #eeeeee; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    direction: rtl;
    column-gap: 30px;
}

.modal-body {
    position: relative;
    /* padding: 10px 30px; */
    display: flex;
    flex-direction: row;
    direction: rtl;
    column-gap: 50px;
    flex-wrap: wrap;
    /* row-gap: 20px; */
    justify-content: center;
    width: 90%;
    margin: 20px auto;
}

.body {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 30px;
}

.coin-type-label {
    position: relative;
}

.coin-type-label>img {
    position: absolute;
    top: 30px;
    left: 10px;
    cursor: pointer;
}

.carts_drop {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    position: absolute;
    background: #656566;
    width: 100%;
    margin-top: 10px;
    z-index: 2;
    border-radius: 5px;
    padding: 5px;
}

.drop-row {
    cursor: pointer;
}

.selected-crypto {
    height: 35px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.04);
    padding: 0 5px;
}

.banks-container {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    column-gap: 10px;
    row-gap: 20px;
}

.peyment-choice {
    width: 150px;
    height: 160px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.peyment-title {
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
}

.authen-warnning-img {
    display: flex;
    align-items: center;
}

/* .modal-body > slot > div > p {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    font-family: shabnam;
  } */
.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 25px;
    padding: 10px;
    cursor: pointer;
    /* font-weight: bold; */
    /* color: #4AAE9B; */
    background: transparent;
}

.cancel-exit-modal {
    border-radius: 2px;
    width: 146px;
    height: 40px;
    border: 0;
    font-family: shabnam;
    font-weight: bold;
    font-size: 16px;
}

.confirm-exit-modal {

    box-sizing: border-box;
    background: transparent;
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
}

.cancel-exit-modal {
    background: #00ACC1;
    color: white;
}

.exit-message-modal>p {
    font-weight: 500;
    font-size: 24px;
    font-family: shabnam;
}

.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .5s ease;
}

hr {
    width: 100%;
    background-image: linear-gradient(90deg, black, rgba(255, 255, 255, 0.6), black);
    border: none;
    height: 1px;
    width: 100%;
}

.buy-btn {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: #0FA902;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    margin: 0 0 30px;
}

label {
    color: #FFFFFF;
}

input {
    background: rgba(255, 255, 255, 0.04);
    border-radius: 5px;
    width: 100%;
    height: 40px;
    border: none;
    color: rgba(255, 255, 255, 0.6);
}

.pay_img {
    width: 100px;
}

@media only screen and (max-width:600px) {
    .banks-container img {
        width: 70px;
    }
}
</style>
