<template>
<div class="container">
    <div class="steps-title-container">
        <p class="steps-title">مراحل انجام کار چیست؟</p>
        <hr>
    </div>
    <div class="step1">
        <div class="subscribe">
            <div>
                <img src="@/assets/subscribe.png" alt="">
            </div>
            <div>
                <p class="steps-subTitle">ثبت نام</p>
                <p class="steps-discribe">با ورود اطلاعات خواسته شده مثل آدرس ایمیل و رمز عبور ثبت نام اولیه را انجام می دهیم. </p>
            </div>
            <div>
                <p class="step-number step-number-odd">1</p>
            </div>
        </div>
        <div class="image-step">
            <img src="@/assets/Group 940.svg" alt="">
        </div>
    </div>
    <div class="step2">
        <div class="authentication">
            <div>
                <img src="@/assets/authentication.png" alt="">
            </div>
            <div>
                <p class="steps-subTitle">احراز هویت</p>
                <p class="steps-discribe">پس از ثبت نام اولیه با ورود اطلاعات فردی و ارسال تصویر کارت ملی و تصویر سلفی میتوانید احراز هویت انجام دهید.</p>
            </div>
            <div>
                <p class="step-number step-number-even">2</p>
            </div>
        </div>
        <div class="image-step">
            <img src="@/assets/Group 1354.svg" alt="">
        </div>
    </div>
    <div class="step3">
        <div class="wallet">
            <div>
                <img class="charg-wallet-img" src="@/assets/charg-wallet.png" alt="">
            </div>
            <div>
                <p class="steps-subTitle">شارژ کیف پول ریالی</p>
                <p class="steps-discribe">با شارژ کیف پول ریالی خود میتوانید در کمترین زمان به سادگی خرید رمز ارزی خود را انجام دهید.</p>
            </div>
            <div>
                <p class="step-number step-number-odd ">3</p>
            </div>
        </div>
        <div class="image-step">
            <img src="@/assets/Group 1355.svg" alt="">
        </div>
    </div>
    <div class="step4">
        <div class="deal">
            <div>
                <img src="@/assets/deal.png" alt="">
            </div>
            <div>
                <p class="steps-subTitle">انجام معاملات</p>
                <p class="steps-discribe">پس از شارژ کیف پول خود می توانید خرید و فروش انجام دهید. آداتتر، با پشتیبانی از ۹ رمز ارز متفاوت امکان معاملات متفاوت را فراهم میکند.</p>
            </div>
            <div>
                <p class="step-number step-number-even">4</p>
            </div>
        </div>
        <div>

        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'HomePage',
    components: {

    },
    data() {
        return {

        }
    },
}
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.steps-title {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
}

hr {
    background-image: linear-gradient(90deg, #2F3239, white, #2F3239);
    width: 200px;
    height: 1px;
    border: 0;
}

.subscribe,
.authentication,
.wallet,
.deal {
    width: 708px;
    background: linear-gradient(180deg, #15161A 0%, #15161A 100%);
    border-radius: 5px;
    width: 56%;
}

.step1,
.step2,
.step3,
.step4,
.subscribe,
.authentication,
.wallet,
.deal {
    display: flex;
    /* height: 130px; */
}

.steps-subTitle {
    font-weight: 500;
    font-size: 18px;
    color: #DBABA7;
}

.steps-discribe {
    font-weight: normal;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
}

.step-number {
    font-weight: 500;
    font-size: 64px;
    color: rgba(255, 255, 255, 0.1);
    margin: 0;
}

.subscribe,
.wallet {
    align-items: center;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
}

.subscribe>div,
.authentication>div,
.wallet>div,
.deal>div {
    padding: 0 20px;
}

.image-step {
    display: flex;
    align-items: center;
    height: 130px;
}

.step2,
.step4 {
    direction: ltr;
}

.authentication,
.deal {
    align-items: center;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
}

.steps-title-container {
    margin-bottom: 20px;
}

.charg-wallet-img {
    width: 80px;
    height: 70px;
}

.step-number-even {
    padding-right: 35px;
}

.step-number-odd {
    padding-left: 35px;
}

@media only screen and (max-width:1000px) {

    .subscribe,
    .authentication,
    .wallet,
    .deal {
        width: 100%;
    }

    .image-step {
        display: none;
    }

    .step2,
    .step4 {
        direction: rtl;
    }

    .subscribe,
    .wallet {
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
    }

    .authentication,
    .deal {
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
    }

    .step1,
    .step2,
    .step3,
    .step4,
    .authentication,
    .deal,
    .subscribe,
    .wallet {
        height: auto;
        padding: 20px;
    }

    .step-number-even {
        padding-right: 0;
    }

    .step-number-odd {
        padding-left: 0;
    }
}

@media only screen and (max-width:500px) {

    .deal,
    .authentication,
    .subscribe,
    .wallet {
        flex-wrap: wrap;
        justify-content: center;
    }

    .steps-subTitle,
    .steps-discribe {
        text-align: center;
    }
}
</style>
