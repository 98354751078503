<template>
<div class="payment-callback-page">
    <div>
        <img class="ada-image" src="@/assets/ada.png" alt="">
    </div>
    <div class="card">
        <div class="header">
            <p v-if="status === 'ACCEPTED'" class="green">تراکنش با موفقیت انجام شده است.</p>
            <p v-if="status === 'REJECTED' || status === 'FAILED'" class="error">متاسفانه در انجام تراکنش خطایی رخ داده است.</p>
            <p v-if="status === 'FAILED' || status === 'REJECTED'" class="describe">در صورت کسر مبلغ، نهایتا تا 72 ساعت آینده به حسابتان برگشت داده خواهد شد.</p>
        </div>
        <div class="body">
            <p class="body_title">اطلاعات تراکنش</p>
            <div class="list">
                <div class="item" v-for="(item, index) in infos" :key="index">
                    <span class="item-key">{{ item.key }}</span>
                    <span class="item-value">{{ item.value }}</span>
                </div>
            </div>
            <button class="panel-main-btn" @click.prevent="$router.push('/dashboard')">بازگشت به داشبورد</button>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'PaymentCallback',
    props: ['id'],
    data() {
        return {
            status: '',
            infos: [{
                    key: 'شناسه تراکنش',
                    value: '-'
                },
                {
                    key: 'مقدار تراکنش',
                    value: '0'
                },
                {
                    key: 'زمان تراکنش',
                    value: '0'
                },
            ]
        }
    },
    methods: {
        async getData() {
            let res = await this.$axios.get('/transactions/' + this.id, {
                headers: {
                    'Authorization': `Bearer ${this.$cookies.get('token')}`
                },

            })
            this.infos[0].value = res.data.data.payment.ref_number ? res.data.data.payment.ref_number : res.data.data.payment.transaction_id ? res.data.data.payment.transaction_id : '-'
            this.infos[1].value = this.$toLocal(res.data.data.amount)
            this.infos[2].value = this.$G2J(res.data.data.payment.updated_at ? res.data.data.payment.updated_at : '-')
            this.status = res.data.data.status
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.payment-callback-page {
    // height: calc(100vh - 100px);
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2f3239;
    background-image: url("../../../assets/bg-pattern.png");
    flex-direction: column;
    padding: 0 10px;
}

.card {
    max-width: 550px;
    max-height: calc(90vh);
    min-height: 400px;
    width: 100%;
    border-radius: 5px;
    padding: 30px 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: rgba(20, 20, 20, 0.4);
    box-shadow: 0px 0px 4px rgba(20, 20, 20, 0.2);
    backdrop-filter: blur(9px);

    .header {
        font-size: 1.2rem;
        margin-bottom: 30px;

        p {
            margin-top: 10px;
            margin-bottom: 15px;
            font-size: 1rem;
            // color: white;
        }
    }

    .body {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex-grow: 1;
        .panel-main-btn {
            background: linear-gradient(180deg, #FFB007 0%, #FF9C07 100%);
            border-radius: 5px;
            font-weight: 600;
            font-size: 16px;
            color: rgba(21, 22, 26, 1);
            border: none;
            width: 200px;
            height: 40px;
            margin: 0 auto;
            // margin: 30px;
        }

        h5 {
            color: var(--secondary_color);
            font-weight: bold;
            font-size: 1.4em;
            margin-bottom: 20px;
            text-align: center;
        }

        .list .item {
            display: flex;
            justify-content: space-between;
            position: relative;
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid;
            align-items: center;
            border-image: linear-gradient(90deg, rgba(255, 195, 36, 0) 0%, #FFC324 51.75%, rgba(255, 195, 36, 0) 99.36%) 1 stretch;
            font-size: clamp(12px , 1.5vw , 16px);
            
            &:last-child {
                border: 0;
            }
            .item-key {
                color: rgba(255, 255, 255 , 0.5);
            }
            .item-value {
                color: white;
            }
        }

    }

}
.describe {
    color: white;
    text-align: center;
}
.error {
    color: red;
    text-align: center;
    margin: 0;
}

.green {
    color: #0FA902;
    text-align: center;
    margin: 0;
}
.body_title {
    color: #FFC324;
    text-align: center;
    font-size: 16px;
}
.ada-image {
    width: 200px;
}
@supports not (backdrop-filter: blur()) {

    .card {
        background: #252833 !important;
    }
}
</style>
