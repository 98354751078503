<template>
<div class="history">
    <p class="history-title">
        تاریخچه
    </p>
    <OrdersHistory/>
    <TransactionsHistory/>
</div>
</template>

<script>
import OrdersHistory from './OrdersHistory.vue'
import TransactionsHistory from './TransactionsHistory.vue'
export default {
    name: 'History',
    components: {
        OrdersHistory,
        TransactionsHistory
    },
    data() {
        return {
        }
    },
    methods: {

    },
}
</script>

<style scoped>
.history {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.history-title {
    font-weight: 500;
    font-size: 20px;
    color: #DBABA7;
}


</style><style>
.v-dropdown-caller {
    width: 100%;
    display: flex !important;
    justify-content: space-around;
    background-color: rgba(21, 22, 26, 0.3);
}

.v-dropdown-container {
    border-radius: 5px;
    border: none !important;
}
</style>
