<template>
<div class="about-body">
    <div class="container">
        <div>
            <p class="about-title">قوانین و مقررات</p>
        </div>
        <div class="about-container">
            <p class="about-sub-title">آداتتر چه میکند؟</p>
            <p v-if="policyContent" class="about-text" v-html="policyContent">

            </p>
            <p class="about-text">
                <img class="policy-image" src="@/assets/policy.png" alt="">
            </p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Ploicy',
    components: {

    },
    data() {
        return {
            policyContent: ''
        }
    },
    methods: {
        async policy() {
            this.state.loading = true
            const givePolicy = await this.$axios('/pages?name=POLICY')
            this.policyContent = givePolicy.data.data[0].content
            this.state.loading = false
        }
    },
    mounted() {
        this.policy()
    }
}
</script>

<style scoped>
.about-body {
    background: #2F3239;
    width: 100%;
    min-height: calc(100vh - 60px);
    background-image: url('../../assets/faq-background.png');
    overflow: auto;
    margin-top: -80px;
}

.about-container {
    border-radius: 5px;
    background: rgba(21, 22, 26, 0.7);
    padding: 10px 40px;
    min-height: 556px;
}

.about-title {
    font-weight: 500;
    font-size: 20px;
    color: #DBABA7;

}

.about-sub-title {
    font-weight: 500;
    font-size: 16px;
    color: #DBABA7;
}

.about-text {
    font-weight: normal;
    font-size: 16px;
    color: #FFFFFF;
}

.about-image-container {
    text-align: left;
}

.policy-image {
    float: left;
    margin: 50px 50px 30px 20px;
    max-width: 100%;
}
</style>
