<template>
<div class="footer">
    <div class="container">
        <div class="top-footer">
            <div class="footer-part">
                <img class="logo-footer" src="@/assets/ada.png" alt="">
                <p class="footer-part-p">آداتتر تابع قوانین جمهوری اسلامی ایران بوده و بستری برای تبادل دارایی‌های دیجیتال می‌باشد. هیچ گونه تبادل ارزی اعم از خرید و فروش دلار یا سایر ارزهای کاغذی، در این بستر صورت نمی گیرد.</p>
            </div>
            <div class="guid-service">
                <div class="">
                    <p class="footer-part-title">راهنما</p>
                    <a :href=guid.link @click.prevent="$router.push(guid.link)" v-for="(guid , index) in guids" :key="index">
                        <p class="bottom-footer-p">
                            {{guid.title}}
                        </p>
                    </a>
                </div>
                <div class="">
                    <p class="footer-part-title">خدمات</p>
                    <p class="bottom-footer-p">خرید رمز ارز</p>
                    <p class="bottom-footer-p">فروش رمز ارز</p>
                </div>
            </div>
            <div class="footer-part contact-part">
                <p class="footer-part-title">تماس با ما</p>
                <div class="footer-contact">
                    <img src="@/assets/location.png" alt="">
                    <p class="footer-contact-p" v-if="social_data!==''">{{social_data.address.option_value}}</p>
                </div>
                <div class="footer-contact-tele">
                    <div class="footer-contact">
                        <img src="@/assets/telephoe.png" alt="">
                        <p class="footer-contact-p" v-if="social_data!==''">{{social_data.phone.option_value}}</p>
                    </div>
                </div>
                <div class="footer-contact">
                    <img class="emial-img" src="@/assets/email.png" alt="">
                    <p class="footer-contact-p" v-if="social_data!==''">{{social_data.email.option_value}}</p>
                </div>
                <div class="footer-contact">
                    <a href="http://www.instagram.com/adatether">
                        <img class="emial-img" src="@/assets/inst.png" alt="">
                    </a>
                    <a href="https://t.me/ADATether">
                        <img class="emial-img" src="@/assets/tl.png" alt="">
                    </a>
                    <a href="https://www.aparat.com/ADATether">
                        <img class="emial-img" src="@/assets/aparat.png" alt="">
                    </a>
                </div>
            </div>
        </div>
        <hr>
        <div class="bottom-footer">
            <div>
                <p class="bottom-footer-p">کلیه حقوق این سایت متعلق به سایت آداتتر می‌باشد.</p>
            </div>
            <div class="contact-icons">
                <a href="https://adatether.com/" target="_blank">
                    adatether.com
                </a>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Footer',
    components: {

    },
    data() {
        return {
            social_data: '',
            guids: [{
                    title: "سوالات متداول",
                    link: '/faq'
                },
                {
                    title: "قوانین و مقررات",
                    link: '/policy'
                },
                {
                    title: "درباره ما",
                    link: '/about'
                },
                {
                    title: "تماس با ما",
                    link: '/contact'
                },
                {
                    title: "بلاگ",
                    link: '/'
                }
            ]
        }
    },
    methods: {
        async social() {
            const res = await this.$axios.get('/options/ADDRESS')
            this.social_data = res.data.data
            this.social_data.forEach(element => {
                this.social_data[element.option_key] = element
            });
        }
    },
    mounted() {
        this.social()
    }
}
</script>

<style scoped lang='scss'>
.footer {
    background: rgba(255, 255, 255, 0.04);
    padding-bottom: 20px;
}

.logo-footer {
    width: 120px;
    height: 120px;
}

.footer-part {
    display: flex;
    flex-direction: column;
    width: 10%;
    min-width: 280px;
    /* flex-grow: 1; */
    /* align-self: center; */
    margin-top: 50px;
}

hr {
    width: 100%;
    height: 1px;
    background-image: linear-gradient(90deg, #3B3E45, rgba(255, 255, 255, 0.5), #3B3E45);
    border: none;
}

.bottom-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

}

.bottom-footer-p {
    font-weight: normal;
    font-size: 14px;
    color: #F4F4F4;
    margin: 5px;
}

.contact-icons {
    display: flex;
    flex-direction: row;
    column-gap: 60px;
    align-items: center;
        a {
            color: white;
        }
}

.footer-part-p {
    color: #F4F4F4;
    font-weight: normal;
    font-size: 14px;
}

.top-footer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    /* min-width: 280px; */
    min-height: 280px;
}

.top-footer>div:first-of-type {
    margin-top: 20px;
}

.footer-contact {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    column-gap: 10px;
}

.footer-contact-p {
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
    margin: 7px;
}

.footer-contact-tele {
    display: flex;
    justify-content: space-between;
    max-width: 200px;
}

.footer-part-title {
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;

}

.guid-service {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    flex-grow: 1;
    max-width: 400px;
    justify-content: space-between;
}

.guid-service>div {
    min-width: 150px;
}

.emial-img {
    width: 24px;
    height: 24px;
}

.contact-part {
    flex-grow: 1;
}

@media only screen and (max-width:1000px) {
    .footer-part {
        flex-grow: 1;
    }
}

@media only screen and (max-width:700px) {
    .bottom-footer {
        justify-content: center;
        column-gap: 20px;
    }
}
</style>
