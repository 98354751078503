<template>
<div class="about-body">
    <div class="container">
        <p class="about-title">سوالات متداول</p>
        <div class="about-container" v-if="test!=''">
            <div class="question-answer" v-for="(question , index) in test.data.data" :key="index" @click="showAnswer(index)">
                <div class="question">
                    <p class="question-text">{{question.question}}</p>
                    <img :class="{'rotate-up':showmessage[index]}" src="@/assets/caretdown.png" alt="">
                </div>
                <div class="answer" v-if="showmessage[index]===true">
                    <p class="answer-text">
                        {{question.answer}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'FAQ',
    components: {
    },
    data() {
        return {
            showmessage: [],
            test: ''
        }
    },
    methods: {
        showAnswer(index) {
            this.showmessage[index] = !this.showmessage[index]
            this.$forceUpdate()
        },
        async getFaq() {
            this.state.loading = true
            this.test = await this.$axios.get('/faqs')
            for (let i = 0; i < this.test.data.data.length; i++) {
                this.showmessage[i] = false
            }
            this.state.loading = false
        }
    },
    mounted() {
        this.getFaq()
    },
}
</script>

<style lang="scss" scoped>
.about-body {
    background-color: #2F3239;
    width: 100%;
    min-height: calc(100vh - 60px);
    background-image: url('../../assets/faq-background.png');
    margin-top: -80px;
}

.about-container {
    padding: 10px 0;
    min-height: 556px;
}

.about-title {
    font-weight: 500;
    font-size: 20px;
    color: #DBABA7;
}

.question-answer {
    height: auto;
    background: rgba(21, 22, 26, 1);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.question {
    width: 100%;
    height: 60px;
    background: rgba(21, 22, 26, 1);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.question {

    p,
    img {
        margin: 0 30px;
    }
}

.question-text {
    font-weight: 500;
    /* font-size: 18px; */
    font-size: clamp(14px, 2vw, 18px);
    color: white;
}

.answer {
    width: 95%;
    margin: 10px auto;
    background: rgba(255, 255, 255, 0.05);
    height: max-content;
}

.answer-text {
    font-weight: normal;
    /* font-size: 14px; */
    font-size: clamp(12px, 2vw, 14px);
    color: #FFFFFF;
    width: 95%;
    margin: 10px auto 40px auto;
}

.hideAnswer {
    display: none;
}

.showAnswer {
    display: inline-block;
}

.rotate-up {
    transform: rotate(-180deg);
    transition: transform 0.2s ease;
}
</style>
