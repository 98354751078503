<template>
<div class="container">
    <div class="tooman-teter">
        <div class="tooman" @click="active">
            <div :class="{ active: toomanActive }">
                تومان
            </div>
        </div>
        <div class="teter" @click="active">
            <div :class="{ active: teterActive }">
                تتر
            </div>
        </div>
    </div>
    <div class="table-container">
        <table>
            <tr class="head-row">
                <td>
                    <p class="title-p-box first-title">رمز ارز</p>
                </td>
                <td class="taghir">
                    <p class="title-p-box">تغییرات</p>
                </td>
                <td>
                    <p class="title-p-box">
                         فروش به شما
                        <span v-if="toomanActive" class="title-sub-box">
                            تومان
                        </span>
                        <span v-if="teterActive" class="title-sub-box">تتر</span>
                    </p>
                </td>
                <td class="sell-td">
                    <p class="title-p-box">
                          خرید از شما 
                        <span v-if="toomanActive" class="title-sub-box">
                            تومان
                        </span>
                        <span v-if="teterActive" class="title-sub-box">تتر</span>
                    </p>
                </td>
                <td class="bitcoin-price">
                    <p class="title-p-box ">نمودار</p>
                </td>
            </tr>
            <template v-for="(crypto, index) in coinList">
                <tr class="row1" :key="index">
                    <td>
                        <div class="first-td-crypto">
                            <img class="box-image" :src=crypto.coin.logo alt="" />
                            <p class="crypto-name">{{ crypto.coin.label }}</p>
                            <p class="crypto-ENname">{{ crypto.coin.code }}</p>
                        </div>
                    </td>
                    <td class="taghir">
                        <span v-if="price[crypto.coin.code]" :class="{
              'negative-change': +price[crypto.coin.code].percentChange < 0,
              'positive-change': +price[crypto.coin.code].percentChange > 0,
            }">
                            {{ $prettifyPrice(Math.abs(price[crypto.coin.code].percentChange), 2) }}
                            <span v-if="+price[crypto.coin.code].percentChange > 0">+</span>
                            <span v-if="+price[crypto.coin.code].percentChange < 0">-</span>
                        </span>
                    </td>

                    <td class="crypto-prices" v-if="toomanActive">
                        <span v-if="price[crypto.coin.code] && $cookies.get('userStatus') && $cookies.get('token')">
                            {{$toLocal($prettifyPrice(price[crypto.coin.code].tomanBuying*(crypto.coin.code !=='USDT' ? (state.correction_factor[crypto.coin.code][0]): 1)))}}
                        </span>
                        <span v-else-if="price[crypto.coin.code]">
                            {{$toLocal($prettifyPrice(price[crypto.coin.code].tomanBuying))}}
                        </span>
                        <span v-else>
                            {{ $toLocal($prettifyPrice((crypto.buyingPrice)))}}
                        </span>
                    </td>
                    <td class="crypto-prices" v-if="teterActive">
                        <span v-if="price[crypto.coin.code]">
                            {{$toLocal( $prettifyPrice(price[crypto.coin.code].buying))}}
                        </span>
                        <span v-else>
                        </span>
                    </td>
                    <td class="crypto-prices sell-td" v-if="toomanActive">
                        <span v-if="price[crypto.coin.code] && $cookies.get('userStatus') && $cookies.get('token')">
                            {{$toLocal($prettifyPrice(price[crypto.coin.code].tomanSelling*(crypto.coin.code !=='USDT' ? (state.correction_factor[crypto.coin.code][1]): 1)))}}
                        </span>
                        <span v-else-if="price[crypto.coin.code]">
                            {{$toLocal($prettifyPrice(price[crypto.coin.code].tomanSelling))}}
                        </span>
                        <span v-else>
                            {{ $toLocal($prettifyPrice((crypto.sellingPrice)))}}
                        </span>
                    </td>
                    <td class="crypto-prices sell-td" v-if="teterActive" >
                        <span v-if="price[crypto.coin.code]">
                            {{ $toLocal( $prettifyPrice(price[crypto.coin.code].selling))}}
                        </span>
                        <span v-else>
                        </span>
                    </td>
                    <td class="bitcoin-price">
                        <TrendChart :trend="trend[crypto.coin.code]" :key="crypto.coin.code + change" />
                    </td>
                </tr>
            </template>
        </table>
    </div>
</div>
</template>

<script>
import TrendChart from "../Tools/TrendChart.vue";
import Echo from 'laravel-echo';
import Pusher from 'pusher-js'
export default {
    name: "CryptoBox",
    components: {
        TrendChart,
    },
    data() {
        return {
            echo: "",
            toomanActive: true,
            teterActive: false,
            cryptoList: [],
            images: [],
            trend: [],
            price: [],
            coinList: [],
            chart: {},
            chartOb: {},
            aa: ["90", "20", "30"],
            change: 0,
            reloadd: false,
            reload: 0,
            test: {
                test1: {
                    sell: 1000
                }
            },
        };
    },
    props: ["value", "reloaded"],
    methods: {
        active() {
            if (this.toomanActive) {
                this.toomanActive = false;
                this.teterActive = true;
            } else {
                this.toomanActive = true;
                this.teterActive = false;
            }
        },
        async chartData() {
            this.chart = await this.$axios.get(
                "/weekly-prices"
            );

            this.chart.data.data.forEach((element) => {
                this.chartOb[element.coin.code] = element.prices;

            });

            for (let key in this.chartOb) {
                this.trend[key] = Object.values(this.chartOb[key])
            }
            ++this.change

        },

        async getCrypto() {

            this.state.loading = true
            this.price = this.state.price

            this.cryptoList = await this.$axios.get('/markets?status=ACTIVATED&size=500')
            for (var i = 0; i <= this.cryptoList.data.data.length - 1; i++) {
                if (this.cryptoList.data.data[i].code == 'TOMAN') {
                    this.cryptoList.data.data.splice(i, 1)
                }

            }

            this.coinList = this.cryptoList.data.data

            this.state.loading = false
        },

    },

    created() {

        Pusher

        this.echo = new Echo({
            broadcaster: 'pusher',
            key: 'adatetherA665A45920',
            wsHost: 'adamanagment.com',
            wsPort: 6001,
            wssPort: 6001,
            forceTLS: true,
            cluster: 'mt1',
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
        });
        this.echo.channel(`realtime-price`)
            .listen('RealTimePriceEvent', (e) => {
                e.prices.forEach(element => {
                    this.state.price[element.coin] = element
                    this.state.price = e;
                });
                this.price = e;

            });
    },
    mounted() {
        this.chartData();

        this.getCrypto()

    },
};
</script>

<style scoped>
.bitcoin-price {
    max-width: 50px;
    max-height: 60px !important;
}

.container {
    /* margin-top: 80px; */
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.3));
}

.table-container {
    width: 100%;
    overflow-x: scroll;
}

.table-container::-webkit-scrollbar {
    display: none;
}

.table-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    /* min-width: 800px; */
}

tr:nth-child(odd) {
    background: #2f3239;
}

tr:nth-child(even) {
    background: #272a30;
}

.first-td-crypto {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.title-p-box {
    color: rgba(255, 176, 7, 1);
    font-weight: 500;
    /* font-size: 16px; */
    font-size: clamp(12px, 1.5vw, 16px);
}

.first-title {
    text-align: right;
    margin-right: 70px;
}

td:first-child {
    padding: 0 60px 0 0;
}

td:last-child {
    padding: 0 0 0 30px;
}

.row1 {
    max-height: 62px !important;
}

.box-image {
    width: 40px;
    height: 40px;
}

.negative-change {
    color: rgba(255, 46, 33, 1);
    font-weight: 500;
    /* font-size: 18px; */
    font-size: clamp(12px, 1.5vw, 16px);
    direction: ltr;
}

.positive-change {
    color: rgba(15, 169, 2, 1);
    font-weight: 500;
    font-size: clamp(12px, 1.5vw, 16px);
    /* font-size: 18px; */
}

.title-sub-box {
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 156, 7, 0.6);
}

.crypto-name {
    color: #ffffff;
    font-weight: 500;
    /* font-size: 16px; */
    text-align: right;
}

.crypto-ENname {
    font-weight: 500;
    /* font-size: 16px; */
    color: rgba(255, 255, 255, 0.6);
}

.crypto-name,
.crypto-ENname {
    font-size: clamp(12px, 1.5vw, 16px);
}

.crypto-prices {
    color: #ffffff;
    font-size: clamp(12px, 1.5vw, 16px);
}

.tooman-teter {
    background: #2f3239;
    display: flex;
    width: 213px;
    height: 45px;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.tooman,
.teter {
    background-color: rgba(21, 22, 26, 0.8);
    width: 101px;
    height: 35px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.active {
    background: linear-gradient(180deg, #ffb007 0%, #ff9c07 100%);
    border-radius: 5px;
    width: 98px;
    height: 27px;
    color: rgba(21, 22, 26, 1);
}

@media only screen and (max-width: 900px) {
    td:first-child {
        padding: 0 20px 0 0;
    }

    td:last-child {
        padding: 0 0 0 20px;
    }
    .box-image , .crypto-ENname , .bitcoin-price , .taghir{
        display: none;
    }
    .first-title {
        margin-right: 0;
    }
}
</style>
