<template>
<div>
    <Loading v-if="this.state.loading" />
    <router-view></router-view>
</div>
</template>

<script>
import Echo from 'laravel-echo';
import Pusher from 'pusher-js'
import Loading from './components/Profile/PublicProfilePages/Loading.vue'
export default {
    name: "App",
    components: {
        Loading
    },
    data() {
        return {
            echo: ''
        }
    },

    created() {

        Pusher

        this.echo = new Echo({
            broadcaster: 'pusher',
            key: 'adatetherA665A45920',
            wsHost: 'adamanagment.com',
            wsPort: 6001,
            wssPort: 6001,
            forceTLS: true,
            cluster: 'mt1',
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
        });
        this.echo.channel(`realtime-price`)
            .listen('RealTimePriceEvent', (e) => {
                let a = {}
                e.prices.forEach(element => {

                    a[element['coin']] = element

                });
                this.state.price = a

            });
    },

}
</script>

<style>

</style>
