import Vue from 'vue';
const that = Vue.prototype

that.$callBackURL = window.location.origin + '/redirect-from-bank'
// that.$google_sso = that.$axios.defaults.baseURL + '/users/google-sso'
that.$refURL = window.location.origin + '/register?ref='

that.$etcChannelCoin = ['DOGE_COIN','BINANCE_COIN_BSC']


that.$coinLabel = {
    TOMAN: 'تومان',
    BITCOIN: 'بیتکوین',
    BITCOIN_CASH: 'بیتکوین کش',
    ETHEREUM: 'اتریوم',
    CLASSIC_ETHEREUM: 'اتریوم کلاسیک',
    LITE_COIN: 'لایت کوین',
    BINANCE_COIN_BSC: 'بایننس کوین',
    BINANCE_COIN: 'بایننس کوین',
    EOS: 'ایاس',
    STELLAR: 'استلار',
    RIPPLE: 'ریپل',
    TRON: 'ترون',
    DASH: 'دش',
    DOGE_COIN: 'دوج',
    TETHER: 'تتر',
    MATIC: 'ماتیک',
    CELO: 'سلو',
    BTC: 'بیتکوین',
    BCH: 'بیتکوین کش',
    ETH: 'اتریوم',
    ETC: 'اتریوم کلاسیک',
    LTC: 'لایت کوین',
    BNB: 'بایننس کوین',
    // EOS: 'ایاس',
    XLM: 'استلار',
    XRP: 'ریپل',
    TRX: 'ترون',
    // DASH: 'دش',
    DOGE: 'دوج',
    USDT: 'تتر',
    bitcoin: 'بیتکوین',
    'bitcoin-cash': 'بیتکوین کش',
    ethereum: 'اتریوم',
    etc: 'اتریوم کلاسیک',
    'ethereum-classic': 'اتریوم کلاسیک',
    litecoin: 'لایت کوین',
    binancecoin: 'بایننس کوین',
    eos: 'ایاس',
    stellar: 'استلار',
    ripple: 'ریپل',
    tron: 'ترون',
    dash: 'دش',
    dogecoin: 'دوج',
    tether: 'تتر',
    'matic-network': 'ماتیک',
    'celo': 'سلو',
}

that.$coinUnit = {
    TOMAN: 'تومان',
    BITCOIN: 'BTC',
    BITCOIN_CASH: 'BCH',
    ETHEREUM: 'ETH',
    CLASSIC_ETHEREUM: 'ETC',
    LITE_COIN: 'LTC',
    BINANCE_COIN: 'BNB',
    BINANCE_COIN_BSC: 'BNB',
    EOS: 'EOS',
    STELLAR: 'XLM',
    RIPPLE: 'XRP',
    TRON: 'TRX',
    DASH: 'DASH',
    DOGE_COIN: 'DOGE',
    TETHER: 'USDT',
    MATIC: 'MATIC',
    CELO: 'CELO',
}

that.$coin2Snake = {
    BTC: 'BITCOIN',
    BTH: 'BITCOIN_CASH',
    ETH: 'ETHEREUM',
    ETC: 'CLASSIC_ETHEREUM',
    BCH: "BITCOIN_CASH",
    LTC: 'LITE_COIN',
    BNB: 'BINANCE_COIN_BSC',
    EOS: 'EOS',
    XLM: 'STELLAR',
    XRP: 'RIPPLE',
    TRX: 'TRON',
    DASH: 'DASH',
    MATIC: 'MATIC',
    CELO: 'CELO',
    DOGE: 'DOGE_COIN',
    USDT: 'TETHER',
    bitcoin: 'BITCOIN',
    'bitcoin-cash': 'BITCOIN_CASH',
    ethereum: 'ETHEREUM',
    etc: 'CLASSIC_ETHEREUM',
    'ethereum-classic': 'CLASSIC_ETHEREUM',
    litecoin: 'LITE_COIN',
    binancecoin: 'BINANCE_COIN_BSC',
    eos: 'EOS',
    stellar: 'STELLAR',
    ripple: 'RIPPLE',
    tron: 'TRON',
    TRON : 'TRON',
    dash: 'DASH',
    dogecoin: 'DOGE_COIN',
    tether: 'TETHER',
    celo: 'CELO',
    'matic-network': 'MATIC',
}
that.$levels = {
    BRONZE : "زنبور عسل",
    SILVER : "زنبور نگهبان",
    GOLD : "زنبور کاوشگر",
    PLATINUM : "زنبور ملکه",
    SUPERVISOR : "سوپر وایزر"
}

that.$sortCoins = ['bitcoin', 'ethereum','ethereum-classic', 'bitcoin-cash', 'binancecoin','litecoin', 'ripple', 'dash', 'dogecoin','stellar','ripple','tron','tether','celo','matic-network']
that.$walletSortCoins = ['TOMAN', 'TETHER', 'BITCOIN', 'ETHEREUM', 'BITCOIN_CASH', 'LITE_COIN', 'RIPPLE','STELLAR', 'CLASSIC_ETHEREUM', 'DASH', 'DOGE_COIN','TRON','BINANCE_COIN','BINANCE_COIN_BSC','CELO','MATIC']
that.$accountLevel = []

that.$errorHandeling = {
    BAD_REQUEST: 'اطلاعات ارسال شده اشتباه است',
    NOT_FOUND: 'اطلاعات درخواستی یافت نشد',
    FINNOTECH_NOT_COMPLETED: 'کاربر فرآیند فینوتک را تکمیل نکرده است',
    CONFLICT: 'اجازه ندارید',
    FORBIDDEN: 'دسترسی لازم را ندارید',
    MOBILE_NUMBER_NOT_FOUND: 'شماره همراه ثبت نشده است',
    AMOUNT_NOT_ALLOWED: 'مقدار وارد شده مجاز نیست',
    'Invalid Phone Number': 'شماره همراه اشتباه است.',
    'Old Password Is Incorrect': 'رمز قبلی اشتباه است.',
    'user not found': 'نام کاربری یافت نشد',
    'Not Enough Balance': 'موجودی شما کافی نمیباشد.',
    'NOT_ENOUGH_BALANCE_TOMAN': 'موجودی تومانی شما کافی نمیباشد.',
    'SMS_OTP_UNAVAILABLE': 'قابلیت پیامک فعال نمیباشد.',
    'MARKET_IS_NOT_ACTIVE': 'این بازار فعال نمیباشد.',
    'incorrect password!!!': 'نام کاربری یا رمز عبور اشتباه است',
    'Incorrect username or password.': 'نام کاربری یا رمز عبور اشتباه است',
    'no user found with entered username': 'نام کاربری یا رمز عبور اشتباه است',
    'Not Enough HDWallets': 'خطایی رخ داده است با پشتیبانی تماس بگیرید',
    'Not Enough Wallets': 'خطایی رخ داده است با پشتیبانی تماس بگیرید',
    'Incorrect OTP': 'کد وارد شده اشتباه است.',
    'Incorrect OTP Code.': 'کد وارد شده اشتباه است.',
    'Otp code is not valid.': 'کد وارد شده اشتباه است.',
    'Code Expired': 'کد شما منقضی شده است.',
    'username exist.': 'اکانت تکراریست',
    'You Dont Have This Coin Wallet' : 'کیف پول این ارز را ندارید',
    'You Don\'t Have Enough Credit' : 'موجودی کافی نیست',
    'You Are Not Allowed To Login!' : 'حساب شما مسدود شده است. جهت اطلاع بیشتر با پشتیبانی تماس بگیرید.',
    EXCEL_NOT_GENERATED : 'اطلاعاتی جهت خروجی اکسل وجود ندارد.',
    CANT_DELETE_WITHDRAWAL_REQUEST : 'درخواست غیرقابل حذف است.',
    ADDRESS_NOT_FOUND : 'آدرسی یافت نشد. جهت ساخت آدرس از دکمه واریز استفاده نمایید.',
    AMOUNT_IS_HIGH : 'میزان درخواست بیش از حد مجاز است.',
    AMOUNT_IS_LOW : 'میزان درخواست کمتر از حد مجاز است.',
    "deleting of order was successful":'حدف سفارش با موفقیت انجام شد'
}
