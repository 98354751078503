<template>
<transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
            <header class="modal-header" id="modalTitle">
                <slot name="header">
                    <button type="button" class="confirm-exit-modal" @click="close" aria-label="Close modal">
                        <img src="@/assets/close-modal.png" alt="">
                    </button>
                    <p class="header">فروش بیت کوین</p>
                </slot>
                <button type="button" class="btn-close" @click="close" aria-label="Close modal">
                    <!-- <img src="./closeModal.png" alt=""> -->
                </button>
            </header>

            <section class="modal-body" id="modalDescription">
                <div name="body" class="body">
                    <div class="body-row">
                        <div>
                            <p>
                                نوع ارز
                            </p>
                            <p>
                                بیت کوین
                            </p>
                        </div>
                        <hr>
                    </div>

                    <div class="body-row">
                        <div>
                            <p>
                                مقدار مورد نظر
                            </p>
                            <p>
                                {{value.amount}}
                            </p>
                        </div>
                        <hr>
                    </div>

                    <div class="body-row">
                        <div>
                            <p>
                                مبلغ کل معامله (تومان)
                            </p>
                            <p>
                                213,213,213,213
                            </p>
                        </div>
                        <hr>
                    </div>

                    <div class="body-row">
                        <div>
                            <p>
                                آدرس کیف بول
                            </p>
                            <p>
                                kjgljhjkhfkuftyfjytfd
                            </p>
                        </div>
                        <hr>
                    </div>
                    <label for="">
                        شناسه تراکنش
                        <input class="peyId" type="text">
                    </label>
                    <div class="qr">
                        <img src="@/assets/QR-code.png" alt="">
                    </div>
                    <button class="sell-btn">
                        ثبت شناسه و فروش
                    </button>
                </div>

            </section>

            <footer class="modal-footer">
            </footer>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    name: 'Modal',
    data() {
        return {

        }
    },
    props: ['value'],
    methods: {
        close() {
            this.$emit('close');
        },

    },
    mounted() {
        console.log(this.value)
    }
};
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    background: linear-gradient(180deg, #15161A 0%, #15161A 100%);
    /* shadow */

    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    min-height: 407px;
    width: 450px;
}

.modal-header {
    padding: 0 15px;
    display: flex;
    position: relative;
    column-gap: 50px;
    align-items: center;
    justify-content: center;
}

.header {
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
}

.modal-header {
    position: relative;
    /* border-bottom: 1px solid #eeeeee; */
    /* color: #4AAE9B; */
    display: flex;
    /* justify-content: center; */
    text-align: right;
    direction: rtl;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

/* .modal-header > slot > p {
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    font-family: shabnam;
  } */

.modal-footer {
    /* border-top: 1px solid #eeeeee; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    direction: rtl;
    column-gap: 30px;
}

.modal-body {
    position: relative;
    /* padding: 10px 30px; */
    display: flex;
    flex-direction: row;
    direction: rtl;
    column-gap: 50px;
    flex-wrap: wrap;
    /* row-gap: 20px; */
    justify-content: center;
    width: 90%;
    margin: 20px auto;
}

.body {
    width: 90%;
    margin: 0 auto;
}

.peyment-choice {
    width: 150px;
    height: 160px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.peyment-title {
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
}

.authen-warnning-img {
    display: flex;
    align-items: center;
}

/* .modal-body > slot > div > p {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    font-family: shabnam;
  } */
.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 25px;
    padding: 10px;
    cursor: pointer;
    /* font-weight: bold; */
    /* color: #4AAE9B; */
    background: transparent;
}

.cancel-exit-modal {
    border-radius: 2px;
    width: 146px;
    height: 40px;
    border: 0;
    font-family: shabnam;
    font-weight: bold;
    font-size: 16px;
}

.confirm-exit-modal {

    box-sizing: border-box;
    background: transparent;
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
}

.cancel-exit-modal {
    background: #00ACC1;
    color: white;
}

.exit-message-modal>p {
    font-weight: 500;
    font-size: 24px;
    font-family: shabnam;
}

.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .5s ease;
}

hr {
    width: 100%;
    background-image: linear-gradient(90deg, black, rgba(255, 255, 255, 0.6), black);
    border: none;
    height: 1px;
    width: 100%;
}

.body-row>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
}

.body-row>div>p {
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
}

.sell-btn {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: #FF2E21;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    margin: 30px 0;
}

.input-file {
    opacity: 0;
    position: absolute;
    right: 0;
    height: 100%;
    width: 100%;
}

.upload-btn {
    width: 100%;
    height: 40px;
    background: #2F3239;
    border-radius: 5px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    color: rgba(21, 22, 26, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px dashed rgba(255, 156, 7, 1);
    color: rgba(255, 255, 255, 0.7);
    margin-top: 20px;
}

.upload-btn>p,
.upload-btn>img {
    margin: 0 10px;
}

.banks-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0;
}

input.peyId {
    background: rgba(255, 255, 255, 0.04);
    border-radius: 5px;
    width: 100%;
    height: 40px;
    border: none;
}

label {
    color: #FFFFFF;
    margin: 20px 0;
}

.qr {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    width: 100%;
}

@media only screen and (max-width:600px) {
    .modal {
        width: 280px;
        overflow: scroll;
        height: 600px;
    }

    .qr img {
        height: 120px;
    }
}
</style>
