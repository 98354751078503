<template>
<transition name="modal-fade">
    <div class="modal-backdrop" @click.self="$emit('close')">
        <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
            <header class="modal-header" id="modalTitle">
                <div class="exit-message-modal">
                    <p>آیا از خروج از حساب کاربری اطمینان دارید؟</p>
                </div>
                <button type="button" class="btn-close" @click="close" aria-label="Close modal">
                    <!-- <img src="@/assets/closeModal.png" alt=""> -->
                </button>
            </header>

            <section class="modal-body" id="modalDescription">
                <slot name="body">
                    <div class="authen-warnning-img">
                        <!-- <img src="@/assets/ExitModal.png" alt=""> -->
                    </div>

                    <button @click="logout" type="button" class="confirm-exit-modal" aria-label="Close modal">
                        خروج
                    </button>

                    <button type="button" class="cancel-exit-modal" @click="close" aria-label="Close modal">
                        انصراف
                    </button>
                </slot>

            </section>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    name: 'Modal',
    methods: {
        close() {
            this.$emit('close');
        },
        async logout() {
            this.state.loading = true
            const out = await this.$axios.delete('/users/logout', {
                headers: {
                    'Authorization': `Bearer ${this.$cookies.get('token')}`
                }
            })
            this.$cookies.remove('token')
            this.$cookies.remove('email')
            this.$cookies.remove('userStatus')
            if (out.data.type == 'success') {
                this.$router.push('/login')
            } else {
                return true
            }
            this.state.loading = false
        }

    },
};
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(200, 200, 200, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;

}

.modal {
    background: linear-gradient(180deg, #15161A 0%, #15161A 100%);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width: 540px;
    min-height: 208px;
    position: relative;
    justify-content: space-around;
}

.modal::before {
    background: url('../../assets/logout-modal.png')no-repeat;
    content: "";
    position: absolute;
    top: 10%;
    right: 0px;
    bottom: 0px;
    left: 35%;
}

.modal-header {
    /* padding: 15px; */
    display: flex;
}

.modal-header {
    position: relative;
    /* border-bottom: 1px solid #eeeeee; */
    /* color: #4AAE9B; */
    display: flex;
    justify-content: center;
}

.modal-footer {
    /* border-top: 1px solid #eeeeee; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    direction: rtl;
    column-gap: 30px;
}

.modal-body {
    position: relative;
    padding: 10px 30px;
    display: flex;
    flex-direction: row;
    direction: rtl;
    column-gap: 20px;
    justify-content: center;
}

.authen-warnning-img {
    display: flex;
    align-items: center;
}

.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 25px;
    padding: 10px;
    cursor: pointer;
    /* font-weight: bold; */
    /* color: #4AAE9B; */
    background: transparent;
}

.cancel-exit-modal,
.confirm-exit-modal {
    width: 122px;
    height: 40px;
    font-weight: bold;
    font-size: 16px;
}

.confirm-exit-modal {
    color: #FFFFFF;
    border: 1px solid #FF2E21;
    box-sizing: border-box;
    background: #FF2E21;
    border-radius: 5px;
}

.cancel-exit-modal {
    background: transparent;
    color: #FF2E21;
    border: 1px solid #FF2E21;
    border-radius: 5px;
}

.exit-message-modal>p {
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    text-align: center;
}

.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .5s ease;
}

@media only screen and (max-width: 600px) {
    .modal {

        width: 300px;
        min-height: 200px;

    }

    .exit-message-modal>p {
        font-size: 20px;
    }

    .confirm-exit-modal,
    .cancel-exit-modal {
        width: 100px;
    }

    .authen-warnning-img>img {
        width: 40px;
    }
}
</style>
