<template>
<span class="timer-entry">
    {{ min }}:{{ sec }}
</span>
</template>

<script>
import moment from 'moment'

export default {
    name: 'TimerBase',
    props: {
        add: {},
        timerName: {
            default: 'timer'
        }
    },
    data() {
        return {
            min: 0,
            sec: 0,
            until: 0,
        }
    },
    methods: {
        initiateTimer() {
            let now = moment().unix()
            this.until = this.$cookies.get(this.timeName) ? parseFloat(this.$cookies.get(this.timerName)) : 0
            if (!this.until || (this.until - now) < 0) {
                this.until = now + this.add * 60
                this.$cookies.set(this.timerName, this.until)
            }
        },
        countDown() {
            var interval = 0
            interval = setInterval(() => {
                let delta = this.until - moment().unix()
                this.min = Math.floor(delta / 60)
                this.sec = delta - this.min * 60
                if (delta <= 0) {
                    clearInterval(interval)
                    this.$emit('finish')
                }
            }, 1000)
        },
        resetTimer() {
            this.initiateTimer()
            this.countDown()
        }
    },
    mounted() {
        this.resetTimer()
    },
    beforeDestroy() {
        clearInterval(this.interval)
    }
}
</script>

<style scoped>
.timer-entry {
    direction: ltr;
    display: inline-block;
}
</style>
