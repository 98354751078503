<template>
    <div class="steps-container" v-if="rank!=''">
      
    <div class="step-container1 step4-container" :class="{'active_level' : value.name==rank[3].name}">
      <div   class="step5">
         <p class="step4-title">
           سطح {{rank[3].label}}
         </p>
         <p class="describe-step4">
           کارمزد {{rank[3].fee}}
           <br><span class="sub-title-level describe-step4">فعالیت در بخش کد رفرال</span>
         </p>
      </div>
    </div>
  
    <div  class="step-container2 step3-container" :class="{'active_level' : value.name==rank[2].name}">
      <div class="step4">
        <p class="step3-title">
          سطح {{rank[2].label}}
        </p>
        <p  class="describe-step3">
          کارمزد {{rank[2].fee}}
          
        </p>
      <svg class="step-svg" height="60" width="50">
            <polygon class="step4-polygon" points="50,0 0,30 50,60"  />
        </svg>
      </div>
    </div>
    <div class="step-container1 step2-container" :class="{'active_level' : value.name==rank[1].name}">
      <div class="step3">
        <p class="step2-title">سطح {{rank[1].label}}</p>
        <p class="describe-step2">
          کارمزد {{rank[1].fee}}
          
        </p>
      <svg class="step-svg" height="60" width="50">
            <polygon class="step3-polygon" points="50,0 0,30 50,60"  />
        </svg>
      </div>
    </div>
    <div class="step-container2 step1-container" :class="{'active_level' : value.name==rank[0].name}">
      <div class="step1">
        <p class="step1-title" >سطح {{rank[0].label}}</p>
        <p  class="describe-step1">
         کارمزد {{rank[0].fee}}
          <br/><span class="sub-title-level">احراز هویت</span>
        </p>
        <svg class="step-svg" height="60" width="50">
            <polygon class="step1-polygon" points="50,0 0,30 50,60"  />
        </svg>
      </div>
    </div>
    
    </div>
</template>

<script>
  export default {
  name: 'AccountLevels',
  components: { },
  props:[
    'value'
  ],
  data(){
    return {
      rank:[]
    }
},
methods : {
  async ranks() {
    const res = await this.$axios.get('/ranks')
    this.rank = res.data.data
  }
},
mounted() {
  this.ranks()
}

  }
</script>

<style scoped>

    body {
  text-align:center;
  direction: rtl;
}
.steps-container {
  display:flex;
  justify-content: center;
  direction: ltr;
  /* width: 1000px; */
  width: 100%;
  min-width: 1200px;
  
}
.step-container1 {
  /* width:1200px; */
  /* height:150px; */
  display:flex;
  justify-content:center;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.3));
  justify-content: flex-end;
  width: 25%;
  cursor: pointer;
  text-align: center;
}
.step-container2 {
  /* width:1200px; */
  /* height:150px; */
  display:flex;
  justify-content:center;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.3));
  justify-content: flex-end;
  width: 25%;
  cursor: pointer;
  text-align: center;
}
.step-svg {
  position:absolute;
  left:-50px;
  
}
.step-polygon {
  fill:#00ACC1;
}
.step1 {
    height: 60px;
  background:white;
  position:relative;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: bold;
font-size: 16px;
width: 100%;
}
.step1-polygon {
    fill: white;
}
.step2 {
    height: 60px;
  background: #90DFE8;
  position:relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00ACC1;
  font-weight: bold;
font-size: 16px;
width: 100%;
}
.step2-polygon {
    fill: #90DFE8;
}
.step3 {
    height: 60px;
  background: #ABABAB;
  position:relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: bold;
font-size: 16px;
width: 100%;
}
.step3-polygon {
    fill: #ABABAB;
}
.step4 {
    height: 60px;
  background: #7e7e7e;
  position:relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: bold;
font-size: 16px;
width: 100%;
}
.step4-polygon {
    fill: #7e7e7e;
}
.step5 {
    height: 60px;
  background: #444343;;
  position:relative;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
font-size: 16px;
width: 100%;
}
.sub-title-level {
  font-weight: normal;
font-size: 14px;
}
.describe-step1 , .describe-step2 , .describe-step3  {
  display: none;
  color: black;
}
.describe-step4 {
  display: none;
  color: #ffff;
}
.step1-container:hover > div >  .describe-step1{
  display: block;
}
.step1-container:hover > div >  .step1-title {
  display: none;
}
.step2-container:hover > div >  .describe-step2{
  display: block;
}
.step2-container:hover > div >  .step2-title {
  display: none;
}
.step3-container:hover > div >  .describe-step3{
  display: block;
}
.step3-container:hover > div >  .step3-title {
  display: none;
}
.step4-container:hover > div >  .describe-step4{
  display: block;
}
.step4-container:hover > div >  .step4-title {
  display: none;
}
/* .describe-step1:hover , .describe-step1:active {
  display: block;
} */
.active_level div, .active_level div svg polygon {
  background: rgba(255, 176, 7, 1);
  fill: rgba(255, 176, 7, 1) !important;

}
</style>
<style >
  .ps {
  height: 70px;
  /* width: 400px; */
}
</style>