<template>
<div class="container">
    <div class="blogItems-title-container">
        <p class="blogItems-title">آخرین مطالب بلاگ</p>
        <hr>
    </div>
    <carousel :rtl='true' :per-page="4" :autoplay='true' :loop='true' :speed='900' :per-page-custom='[[320, 1], [1000, 4]]' :autoplay-timeout="3000">
<slide v-for="(post, index) in posts" :key="index">
           <div class="blog-item" >
                <div>
                     <img
                    v-if="post._embedded['wp:featuredmedia']"
              :src="post._embedded['wp:featuredmedia'][0].source_url"
            width="244" height="151" />
                </div>
                <div class="blog-text">
                    
                    <a :href="post.link" target="_blank" class="item-title">{{ post.title.rendered }}</a>
                    <p class="item-discribe">{{ post.excerpt.rendered }}</p>
                </div>
            </div>
        </slide>
    </carousel>
            
</div>
</template>

<script>
import axios from "axios";
import {
    Carousel,
    Slide
} from 'vue-carousel';
export default {
    components: {
        Carousel,
        Slide,
    },
    data() {
        return {
      // Wordpress Posts Endpoint
      postsUrl: "https://adatether.com/blog/wp-json/wp/v2/posts",
      queryOptions: {
        per_page: 10, // Only retrieve the 10 most recent blog posts.
        page: 1, // Current page of the collection.
        _embed: true //Response should include embedded resources.
      },
      // Returned Posts in an Array
      posts: []
    };
  },
  methods: {
    // Get Recent Posts From WordPress Site
    getRecentMessages() {
      axios
        .get(this.postsUrl, { params: this.queryOptions })
        .then(response => {
          this.posts = response.data;
          console.log("Posts retrieved!");
          console.log(this.posts);
        })
        .catch(error => {
          console.log(error);
        });
    }
    },
  
  mounted() {
    this.getRecentMessages();
  }
};
</script>

<style scoped>
.slide {
    background: rgba(255, 255, 255, 0.04);
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(20px);
    border-radius: 5px;
    width: 305px;
    height: 91px;
    display: flex;
    justify-content: space-between;
}

hr {
    height: 1px;
    width: 250px;
    background-image: linear-gradient(90deg, transparent, white, transparent);
    border: 0;
}

.blogItems-title {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;

}

.blog-item {
    width: 244px;
    height: 380px;
    background: #151619;
}

.blog-text {
    padding: 10px 10px 40px 10px;
}

.item-title {
    font-weight: 500;
    font-size: 16px;
    color: #DBABA7;
}

.item-discribe {
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);

}

.blogItems-title-container {
    margin-bottom: 60px;
}
</style>
