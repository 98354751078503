<template>
    <div class="login">
        <div class="form-title">
            شناسایی دو عاملی
        </div>
        <hr>
        <label>
            <input type="text">
        </label>
    </div>
</template>
<script>
export default {
    name:"FinoVerifying",
    components:{

    },
    data() {
        return {

        }
    },
    methods: {

    },
    mounted() {
        
    },
}
</script>
<style lang="scss" scoped>
    .login , form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
    .form-title {
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
        text-align: center;
    }
    hr {
        border: 0;
        height: 1px;
        background-image: linear-gradient(90deg , black , white , black);
    }
    label , input {
        width: 100%;
        /* background: #212225; */
        border-radius: 5px;
        border: 0;
    }
    label {
        font-weight: normal;
        font-size: 16px;
        color: #FFFFFF;
        position: relative;
        display: block;
    }
    input {
        height: 40px;
        color: rgba(255, 255, 255, 0.7);
        background: rgba(255, 255, 255, 0.04);
        
    }
</style>