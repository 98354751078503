<template>
  <div class="login">
    <div class="login-form-title">تغییر رمز عبور</div>
    <div>
      <hr />
    </div>
    <form @submit.prevent="send">
      <div>
        <label for="">
          <button class="show-pass" @click="showPassword">
            <img class="show-pass-image" src="@/assets/showpass.png" />
          </button>
          رمز عبور
          <input class="password" :type="type" v-model="data.password" />
        </label>
      </div>
      <div>
        <label for="">
          <button class="show-pass" @click="showPassword">
            <img class="show-pass-image" src="@/assets/showpass.png" />
          </button>
          تکرار رمز عبور
          <input
            class="password"
            :type="type"
            v-model="data.password_confirmation"
          />
        </label>
      </div>
      <div>
        <button
          class="login-btn"
          type="submit"
          :disabled="!data.password_confirmation || !data.password"
        >
          تغییر رمز عبور
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "ChangePass",
  components: {},
  data() {
    return {
      type: "password",
      btnText: "Show Password",
      pass: {
        password: "",
        password_confirmation: "",
      },
      data:{
        email:this.$cookies.get('forgetEmail'),
        password:"",
        password_confirmation:"",
        token:this.$cookies.get('forgetToken')
      }
    };
  },
  methods: {
    showPassword(e) {
      if (this.type === "password") {
        this.type = "text";
        this.btnText = "Hide Password";
      } else {
        this.type = "password";
        this.btnText = "Show Password";
      }
      e.preventDefault();
    },
    async send() {
      this.state.success = true
      try {
         await this.$axios.post(
        "/change-password",
        this.data,
        { headers: { Authorization: `Bearer ${this.$cookies.get('forgetToken')}` } }
      );
      this.$router.push("/loign")
      }
      catch(err) {
        const res = err.response || err.toJSON();
        console.log(res)
      }
       this.state.success = false
    },
  },
};
</script>

<style scoped>
.login,
form {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.login-form-title {
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
}
hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(90deg, black, white, black);
}
label,
input {
  width: 100%;
  background: #212225;
  border-radius: 5px;
  border: 0;
}
label {
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  position: relative;
}
input {
  height: 40px;
  color: rgba(255, 255, 255, 0.7);
}
.login-btn {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background: linear-gradient(180deg, #ffb007 0%, #ff9c07 100%);
  border: 0;
  font-weight: 500;
  font-size: 16px;
}
.sub-link {
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
}
.link-p {
  text-decoration-line: underline;
  color: #53acff;
}
.google-btn {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  background: transparent;
  color: #ffffff;
  height: 50px;
}
.forget-a {
  margin: 0;
  text-align: left;
}
.forget-a > a {
  text-decoration-line: underline;
  color: #ffffff;
  font-weight: normal;
  font-size: 12px;
}
.show-pass {
  border: 0;
  background: transparent;
  position: absolute;
  left: 5px;
  top: 35px;
  cursor: pointer;
}
</style>
