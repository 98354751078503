<template>
<transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
            <header class="modal-header" id="modalTitle">
                <slot name="header">
                    <button type="button" class="confirm-exit-modal" @click="close" aria-label="Close modal">
                        <img src="@/assets/close-modal.png" alt="">
                    </button>
                    <p class="header">برداشت </p>
                </slot>
                <button type="button" class="btn-close" @click="close" aria-label="Close modal">
                    <!-- <img src="./closeModal.png" alt=""> -->
                </button>
            </header>

            <section class="modal-body" id="modalDescription">
                <div name="body" class="body">
                    <label for="" class="coin-type-label"> انتخاب شماره کارت
                        <div class="selected-crypto">

                            <span v-if="selectedCard != ''">{{selectedCard}}</span>

                        </div>
                        <img class="drop-img coin-drop" src="@/assets/caretdown.png" alt="" @click="showAccounts" />

                        <div v-if="show" class="carts_drop">
                            <div class="drop-row" v-for="(account, index) in accounts" :key="index" @click="selectCard(index)">
                                <!-- <img :src="cryptoImages[crypto.coin.code].img" alt="" /> -->
                                <span class="drop-name">{{ account.card_number }}</span>

                            </div>
                        </div>
                        <!-- <p class="error-p" >
                {{errorText}}
              </p> -->
                    </label>
                    <!-- <label for="">
                    شماره شبا
                    <input type="text">
                  </label> -->
                    <label for="">
                        مبلغ
                        <input type="text" v-model="data.amount" @input="data.amount = $toLocal($p2e(data.amount))">
                        <p class="error-p">
                            {{amountErr}}
                        </p>
                    </label>
                    <label for="" v-if="codeReceived ">
                        <span v-if="state.setting_value=='EMAIL'">
                            کد ارسال شده به ایمیل خود را وارد نمایید
                        </span>
                        <span v-if="state.setting_value=='SMS'">
                            کد ارسال شده به شماره موبایل خود را وارد نمایید
                        </span>
                        <span v-if="state.setting_value=='GOOGLE'">
                            کد تایید دومرحله ای که برنامه Google Authenticator تولید کرده است را وارد نمایید.
                        </span>
                        <input type="text" v-model="data.code" @input="data.code = $p2e(data.code)">
                        <p class="count" v-if="state.setting_value=='EMAIL'">
                            <TimerBase @finish="resendBtn=true" :timerName="'WithdrawEmail'" :add="5" ref="timer" /> ثانیه
                        </p>
                        <p class="count" v-if="state.setting_value=='SMS'">
                            <TimerBase @finish="resendBtn=true" :timerName="'WithdrawSms'" :add="2" ref="timer" /> ثانیه
                        </p>
                    </label>
                    <p class="error-p">
                        {{ErrorText}}
                    </p>

                    <button class="withdrawal-btn" v-if="codeReceived && resendBtn==false" @click="withdrawal" :disabled="data.code==null || data.amount==null || selectedCard==''">
                        برداشت وجه
                    </button>
                    <button class="withdrawal-btn" v-if="receiveCode" @click="getCode">
                        دریافت کد دوعاملی
                    </button>
                    <button class="withdrawal-btn" v-if="codeReceived && resendBtn" @click="resendCode">
                        ارسال مجدد
                    </button>
                    <button @click="$emit('close')" class="cancel_btn">
                        انصراف 
                    </button>
                </div>

            </section>

            <footer class="modal-footer">

                <!-- <slot name="footer">
                This is the default footer!
              </slot> -->
                <!-- <button
                type="button"
                class="cancel-exit-modal"
                @click="close"
                aria-label="Close modal"
              >
                 انصراف 
              </button>
              <button
                type="button"
                class="confirm-exit-modal"
                @click="close"
                aria-label="Close modal"
              >
                خروج
              </button> -->
            </footer>
        </div>
    </div>
</transition>
</template>

<script>
import TimerBase from '../../Tools/TimerBase.vue'

export default {
    name: 'Modal',
    components: {
        TimerBase
    },
    data() {
        return {
            setting_value: '',
            accounts: [],
            selectedCard: '',
            resendBtn: false,
            show: false,
            ErrorText: '',
            amountErr: '',
            codeReceived: false,
            receiveCode: true,
            count: 60,
            data: {
                payment_method: 'TRANSFER',
                amount: null,
                coin_id: 1,
                account_id: null,
                code: null
            }
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        showAccounts() {
            this.show = !this.show
        },
        selectCard(index) {
            this.selectedCard = this.accounts[index].card_number
            this.data.account_id = this.accounts[index].id
            this.show = false
        },
        async accountInfo() {
            const res = await this.$axios.get(
                "/accounts?status=ACCEPTED", {
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get('token')}`
                    }
                }
            )
            this.accounts = res.data.data
            console.log(this.info)
        },
        async personalInfo() {
            const res = await this.$axios.get(
                "/settings/otp", {
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get('token')}`
                    }
                }
            )
            this.setting_value = res.data.data.setting_value
        },
        inputCode() {
            if (this.count > 0) {
                this.timeOut = setTimeout(() => {
                    this.count--
                    this.inputCode()
                }, 1000)
            }
        },
        async getCode() {
            this.state.loading = true
            this.receiveCode = false
            this.codeReceived = true
            await this.$axios.post(
                "/transactions/otp",
                null, {
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get('token')}`
                    }
                }
            )
            this.state.loading = false
            this.inputCode()
        },
        resendCode() {
            this.receiveCode = false
            this.codeReceived = true
            this.resendBtn = false
            this.getCode()
            this.$refs.timer.resetTimer()
            // this.inputCode()
        },
        async withdrawal() {
            this.data.amount = this.$S2N(this.data.amount)
            this.state.loading = true
            try {
                await this.$axios.post(
                    "/transactions/type/withdraw", this.data, {
                        headers: {
                            Authorization: `Bearer ${this.$cookies.get('token')}`
                        }
                    }
                )
                const resp2 = "";
                    try{
                    this.resp2 = await this.$axios.get("https://login.parsgreen.com/UrlService/sendSMS.ashx?from=5000283100&to=9122122657&text=درخواست برداشت تومان ثبت شد&signature=67CEE40F-121F-48DA-B8A8-6783A1A733F0");
                    console.log(resp2.data);
                    } catch (err){
                        console.log(resp2.data);
                    }
                this.$emit('close');
                this.state.loading = false
                this.state.success = true
            } catch (err) {
                const error = err.response || err.toJSON();
                if (error.data.errors) {
                    this.amountErr = error.data.errors.amount
                }

                this.ErrorText = error.data.message

                this.state.loading = false
            }
        }

    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },
    mounted() {
        this.accountInfo()
        this.personalInfo()
    }
};
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    background: linear-gradient(180deg, #15161A 0%, #15161A 100%);
    /* shadow */

    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    min-height: 407px;
    width: 450px;
}

.modal-header {
    padding: 0 15px;
    display: flex;
    position: relative;
    column-gap: 50px;
    align-items: center;
    justify-content: center;
}

.header {
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
}

.modal-header {
    position: relative;
    /* border-bottom: 1px solid #eeeeee; */
    /* color: #4AAE9B; */
    display: flex;
    /* justify-content: center; */
    text-align: right;
    direction: rtl;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

/* .modal-header > slot > p {
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    font-family: shabnam;
  } */

.modal-footer {
    /* border-top: 1px solid #eeeeee; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    direction: rtl;
    column-gap: 30px;
}

.modal-body {
    position: relative;
    /* padding: 10px 30px; */
    display: flex;
    flex-direction: row;
    direction: rtl;
    column-gap: 50px;
    flex-wrap: wrap;
    /* row-gap: 20px; */
    justify-content: center;
    width: 90%;
    margin: 20px auto;
}

.body {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 25px;
    padding: 10px;
    cursor: pointer;
    /* font-weight: bold; */
    /* color: #4AAE9B; */
    background: transparent;
}

.cancel-exit-modal {
    border-radius: 2px;
    width: 146px;
    height: 40px;
    border: 0;
    font-family: shabnam;
    font-weight: bold;
    font-size: 16px;
}

.confirm-exit-modal {

    box-sizing: border-box;
    background: transparent;
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
}

.cancel-exit-modal {
    background: #00ACC1;
    color: white;
}

.exit-message-modal>p {
    font-weight: 500;
    font-size: 24px;
    font-family: shabnam;
}

.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .5s ease;
}

hr {
    width: 100%;
    background-image: linear-gradient(90deg, black, rgba(255, 255, 255, 0.6), black);
    border: none;
    height: 1px;
    width: 100%;
}

.body-row>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
}

.body-row>div>p {
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
}

.withdrawal-btn {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: #FF2E21;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    margin: 30px 0;
}

.withdrawal-btn:disabled {
    opacity: 0.5;
}

input {
    width: 100%;
    height: 40px;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 5px;
    border: none;
    color: rgba(255, 255, 255, 0.6);
}

label {
    font-weight: normal;
    font-size: 16px;
    color: #FFFFFF;
    margin: 5px 0;
}

.coin-type-label {
    position: relative;
}

.coin-type-label>img {
    position: absolute;
    top: 30px;
    left: 10px;
    cursor: pointer;
}

.carts_drop {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    position: absolute;
    background: #656566;
    width: 100%;
    margin-top: 10px;
    z-index: 2;
    border-radius: 5px;
    padding: 5px;
}

.drop-row {
    cursor: pointer;
}

.selected-crypto {
    height: 35px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.04);
    padding: 0 5px;
}

.count {
    color: #ececec;
}
.cancel_btn {
    height: 40px;
    width: 100%;
    border-radius: 6px;
    font-weight: bold;
    font-size: 16px;
    border: 1px solid #FF2E21;
    color: #FF2E21;
    background: transparent;
}
</style>
