<template>
    <transition >
        <div class="modal-backdrop" >
            <div class="modal">
                <img @click="closeModale" class="close-icon" src="@/assets/close-modal.png" alt="">
                <span class="modal-title">
                    شناسایی دو عاملی
                </span>
                <div class="input-container">
                    <span class="describe">
                    برای فعالسازی شناسایی دوعاملی،ابتدا برنامه‌ی Google Authenticator را دانلود نموده و سپس QR کد زیر را توسط آن اسکن نمایید.
                    </span>
                    <div class="address_image" v-html="googleInfo.qr_image"></div>
                    <span class="qr_text">
                      {{googleInfo.secret_key}}
                    </span>
                    <span class="describe">
                    پس از اسکن بارکد، مورد مربوط به آداتتر در برنامه شما ایجاد خواهد شد. برای تایید صحت فرآیند، کدی که در اپ خود مشاهده می‌کنید را در کادر زیر وارد نمایید.
                    </span>
                    <CodeInput :loading="false"  v-on:complete="onComplete" @change="setcode" v-model="data.code"/>
                    <p class="error-p">
                      {{errorText}}
                    </p>
                </div>
                    <button :disabled=disablebtn @click="send"
                            :class="{'deActive-btn' :this.disablebtn , 'active-btn' : !this.disablebtn}">
                    ثبت
                    </button>
            </div>
        </div>
    </transition>
</template>

<script>
import CodeInput from "vue-verification-code-input";
export default {
  name: 'GoogleTwoStep',
  components :{
    CodeInput
  },
  data() {
      return {
        close:false,
        disablebtn:true,
        googleInfo : {},
        data: {
          code : null
        },
        errorText:""
      }
  },
  methods : {
      closeModale() {
        this.$emit('closeModal' , this.close)
      },
      onComplete() {
        this.disablebtn=false
        this.send()
      },
     async getInfo() {
       this.state.loading = true
       const res = await this.$axios.get("/settings/google-2fa",
                 {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}})
                 this.googleInfo = res.data.data
                 this.state.loading = false 
      },
      setcode(e) {
        this.data.code = e
      },
      async send() {
        this.state.loading = true
        try {
          await this.$axios.post(
          '/settings/google-2fa/verify',
          this.data ,
            {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}}
        )
        this.closeModale()
        this.state.success = true
        }
        catch(err) {
          const res = err.response || err.toJSON();
          this.errorText = res.data.message
        }
        this.state.loading = false
      }
  },
  mounted() {
    this.getInfo()
  },
  created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest('.modal , .checkbox')) {
              this.$emit('closeModal' , this.close)
            }
            // else if(event.target.closest('.modal , .add-btn')) {
            // }
            
          } )
  },
  }
</script>

<style lang="scss" scoped>
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .modal {
    display: flex;
    flex-grow: 1;
    max-width: 384px;
    min-height: 501px;
    background: rgba(21, 22, 26, 1);
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 8px;
    position: relative;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 20px;
  }
  .close-icon {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 16px;
  }
    .modal-title {
        font-weight: bold;
        font-size: 24px;
        color: #FEFEFE;
    }
    .input-container {
        display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    }
    .describe {
      font-size: 12px;
      color: #FEFEFE;
    }
    .deActive-btn , .active-btn {
      border-radius: 6px;
      border: none;
      color: #FCFCFC;
      font-weight: bold;
      font-size: 14px;
      width: 100%;
      height: 44px;
    }
    .active-btn {
      background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    }
    .deActive-btn {
      background: linear-gradient(94.21deg, rgba(255, 245, 4, 0.5) 0%, rgba(255, 199, 0, 0.5) 47.18%, rgba(244, 132, 0, 0.5) 99.06%);
    }
    .qr_text {
      color: #FCFCFC;
      border: 1px solid gray;
      border-radius: 5px;
      padding: 5px 10px;
    }
</style>
