<template>
    <div class="authen-part">
        <div class="title">
            <p>اطلاعات تماس</p>
        </div>
        <div class="describe">
            <p>
            </p>
        </div>
        <div class="inputs">
            <label for="">
                تلفن همراه
                <input type="text" v-model="info.mobile" @input="info.mobile = $p2e(info.mobile)" :disabled="$cookies.get('userStatus')=='ACCEPTED' || $cookies.get('userStatus')=='CONFIRMED_IDENTITY'">
                <p class="error-p">
                    {{err.mobile}}
                </p>
            </label>
            <label for="">
                آدرس ایمیل  
                <input type="text" v-model="email" disabled>
                <!-- <p class="error-p">
                    {{err.email}}
                </p> -->
            </label>
            <label for="">
                تلفن ثابت 
                <input type="text" v-model="info.phone" @input="info.phone = $p2e(info.phone)" :disabled="$cookies.get('userStatus')=='ACCEPTED' || $cookies.get('userStatus')=='CONFIRMED_IDENTITY'">
                <p class="error-p">
                    {{err.phone}}
                </p>
            </label>
        </div>
        <div class="textarea">
            <label for="">
                آدرس
                <textarea name="" id="" cols="30" rows="10" v-model="info.address" :disabled="$cookies.get('userStatus')=='ACCEPTED' || $cookies.get('userStatus')=='CONFIRMED_IDENTITY'">

                </textarea>
                <p class="error-p">
                    {{err.address}}
                </p>
            </label>
        </div>
    </div>
</template>
<script>
export default {
    name:"ContactInformation",
    components :{

    },
    props:{value:Object,
    sendError:Object},
    data() {
        return {
             err : this.sendError,
             info : this.value,
             email : this.$cookies.get('email')
        }
    },
    methods : {
        // toPrent() {
        //     this.$emit('childData' , this.data)
        // }
    }
}
</script>
<style lang="scss" scoped>

    .authen-part {
        border-radius: 5px;
        background: #1D1F23;
            .title {
                text-align: center;
                color: rgba(255, 255, 255, 0.6);
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            }
            .describe {
                font-weight: normal;
                font-size: clamp(12px , 2vw , 14px);
                text-align: right;
                color: #FFFFFF;
                width: 95%;
                margin: auto;
            }
            .inputs {
                display: flex;
                width: 95%;
                margin: auto;
                column-gap: 100px;
                align-items: center;
                margin-top: 30px;
                padding-bottom: 30px;
                flex-wrap: wrap;
                row-gap: 20px;
            }
    }
    label {
        flex-basis: 200px;
        flex-grow: 1;
        }
</style>