<template>
<div class="login">
    <div class="login-form-title">فراموشی رمز عبور</div>
    <div>
        <hr />
    </div>
    <form @submit.prevent="send">
        <div>
            <!-- <label for="">
                ایمیل
                <input type="text" v-model="data.email" disabled />
            </label> -->
        </div>
        <div>
            <label for="" v-if="state.method == 'EMAIL'">
                <span class="second">
                    <TimerBase @finish="resendBtn=true" :timerName="'EmailOTP'" :add="5" ref="timer" /></span>
                کد ارسال شده به ایمیل
                <input type="text" v-model="data.code" />
            </label>
            <label for="" v-if="state.method== 'SMS'">
                <span class="second">
                    <TimerBase @finish="resendBtn=true" :timerName="'SMS'" :add="2" ref="timer" /></span>
                کد ارسال شده به موبایل را وارد نمایید
                <input type="text" v-model="data.code" />
            </label>
            <label for="" v-if="state.method == 'GOOGLE'">
                <!-- <span class="second">{{ this.second }}</span> -->
                کد تایید دومرحله ای که برنامه Google Authenticator تولید کرده است را وارد نمایید.
                <input type="text" v-model="data.code" />
            </label>
            <p class="error-p">
                {{ErrorText}}
            </p>
            <p class="error-p">
                {{ErrorText}}
            </p>
        </div>
        <div>
            <button v-if="!resendBtn" class="login-btn" type="submit">تایید کد</button>
            <button v-else class="login-btn" @click="reSend">دریافت مجدد کد</button>

        </div>
    </form>
</div>
</template>

<script>
import TimerBase from '../Tools/TimerBase.vue'
export default {
    name: "ForgetConfirm",
    components: {TimerBase},
    data() {
        return {
            resendBtn:false,
            data: {
                email: this.state.email,
                code: null,

            },
            ErrorText: "",
            codeError: "",
            setting:'',
            setting_value:''
        };
    },
    methods: {
        async getInfo() {
            const info = await this.$axios.get(
                "/settings/otp", {
                    headers: {
                        'Authorization': `Bearer ${this.$cookies.get('token')}`
                    }
                }
            )

            this.setting = info.data.data
            this.setting_value = info.data.data.setting_value
        },
        async send() {
            this.state.loading = true
            try {
                const res = await this.$axios.post(
                    "/forget-password-confirm",
                    this.data
                );
                //   if (res.data.type == 'success') {
                this.$cookies.set('test', "res.da")
                this.$cookies.set('forgetToken', res.data.data.token)
                this.$cookies.set('forgetEmail', this.data.email)
                // localStorage.setItem('forgetToken' , res.data.data.token)
                // localStorage.setItem('forgetEmail' , this.data.email)

                await this.$router.push("/change-pass");
                //   }
                //   this.state.loading = false
            } catch (err) {
                const res = err.response || err.toJSON();
                if (res.data.errors) {
                    this.ErrorText = res.data.errors.code[0];
                } else {
                    this.ErrorText = res.data.messages
                }
                // this.ErrorText = res.data.message 
                // this.codeError = res.data.errors.code
                // if(res.data.message &&  this.codeError!== ""){
                //     this.ErrorText = res.data.message 
                // }
                // else if (res.data.errors.code) {
                //     this.codeError = res.data.errors.code[0] 
                //     }

                this.state.loading = false
                return
            }
            this.state.loading = false
            this.ErrorText = ''
            this.codeError = ''
        },
        async reSend() {
            this.state.loading = true
            this.$refs.timer.resetTimer()
            try {
                this.data.email = this.state.email;
                await this.$axios.post(
                    "/forget-password",
                    this.data
                );
                //   if (res.data.type == 'success') {
                this.$router.push("/change-pass");
                //   }
            } catch (err) {
                const res = err.response || err.toJSON();
                console.log(res)
                // this.ErrorText = res.data.errors.email[0];
                // if(res.data.message &&  this.codeError!== ""){
                //     this.ErrorText = res.data.message 
                // }
                // else if (res.data.errors.code) {
                //     this.codeError = res.data.errors.code[0] 
                //     }
            }
            this.state.loading = false
        },

    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },
    async mounted() {
        this.data.email = this.state.email;
        await this.getInfo()
    },
};
</script>

<style scoped>
.login,
form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.login-form-title {
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
}

hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(90deg, black, white, black);
}

label,
input {
    width: 100%;
    /* background: #212225; */
    border-radius: 5px;
    border: 0;
}

label {
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    position: relative;
}

input {
    height: 40px;
    color: rgba(255, 255, 255, 0.7);
    background: rgba(255, 255, 255, 0.04);
}

.login-btn {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    background: linear-gradient(180deg, #ffb007 0%, #ff9c07 100%);
    border: 0;
    font-weight: 500;
    font-size: 16px;
}

.sub-link {
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;
}

.link-p {
    text-decoration-line: underline;
    color: #53acff;
}

.google-btn {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    background: transparent;
    color: #ffffff;
    height: 50px;
}

.forget-a {
    margin: 0;
    text-align: left;
}

.forget-a>a {
    text-decoration-line: underline;
    color: #ffffff;
    font-weight: normal;
    font-size: 12px;
}

.second {
    border: 0;
    background: #2b2d30;
    position: absolute;
    width: 45px;
    left: 1px;
    top: 26px;
    height: 37px;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}
</style>
